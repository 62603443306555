.selectStatus {
  width: 160px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding-left: 10px;
  outline: none;
}

.inputGroup {
  position: relative;
  display: flex;
}

.clearSearch {
  position: absolute !important;
  right: 45px;
}

/* Table */
.divTable {
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.tableWrap {
  width: max-content;
}

.trow {
  display: flex;
}

.bgRefund {
  background: #F0F0F0 !important;
}

.thead .trow .tdata {
  height: 65px;
}

.tbody {
  border-bottom: 1px solid #dee2e6;
}

.tdata {
  padding: 5px;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tdata.no {
  width: 60px;
  border-left: 1px solid #dee2e6;
  flex-shrink: 0;
}

.tdata.clientName,
.tdata.rekeningRefund {
  width: 230px;
  flex-shrink: 0;
}

.tdata.rekeningRefund {
  height: 65px;
}

.tdata.projectId,
.tdata.materialOrder,
.tdata.percentage {
  width: 110px;
  flex-shrink: 0;
}

.tdata.amount,
.tdata.feeAdmin,
.tdata.feeRefund,
.tdata.totalRefund {
  width: 130px;
  flex-shrink: 0;
}

.tbody .tdata.rekeningRefund {
  justify-content: start;
  padding-left: 10px;
}

.dataRekening {
  text-align: left;
}

.tdata.action {
  position: sticky;
  right: -0.5px;
  width: 100px;
  flex-shrink: 0;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #dee2e6;
  z-index: 2;
}

.paidText {
  background: #10BD41;
  padding: 3px 10px;
  border-radius: 100px;
  color: white;
}

.btnDetail {
  background: #5A9DFD;
}

.emptyData {
  border: 1px solid #dee2e6;
  width: inherit;
  padding: 20px 0px 40px 450px;
}

.textMaterial, .textVariant, .textSubVariant {
  width: 240px;
  white-space: normal;
}

.textBank {
  overflow: hidden;
  width: 180px;
  text-overflow: ellipsis;
  cursor: default;
}

/* Select Row Perpage */
.selectRowPerPage {
  width: 90px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding-left: 10px;
  outline: none;
}

.wrapButtonClose {
  border-top: 1px solid #dee2e6;
  margin-right: -16px;
  margin-left: -16px;
}

/* Tooltip */

.tooltipCustom {
  position: absolute;
  visibility: hidden;
  width: max-content;
  top: 90%;
  right: 50px;
  z-index: 1;
  padding: 5px 10px;
}

.tooltipCustom .tooltipInner {
  width: max-content;
  padding: 0.5rem 1rem;
  color: white;
  text-align: center;
  background-color: black;
  border-radius: 3px;
}

/* Edit Button Rekening */

.editButton {
  background: white;
  padding: 8px 10px;
  color: #E10009;
  cursor: pointer;
  border: 1px solid #E10009;
  width: 90%;
  border-radius: 6px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Disable Input */
.inputGroupText {
  border: 1px solid white;
  border-radius: 0px;
}

.disableInput {
  border: 1px solid #E1E1E1;
  border-radius: 0px;
}

.disableInput:disabled {
  background: #F5F5F5 !important;
}

/* Button in Modal Confirm */

.btnEdit {
  border: 1px solid #E10009;
  color: #E10009;
  background: white;
  width: 200px;
}

.btnEdit:hover {
  color: #E10009;
  background: #fafafa;
}

.btnConfirm {
  width: 200px;
}