.selectStatus {
  width: 160px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding-left: 10px;
  outline: none;
}

.inputGroup {
  position: relative;
  display: flex;
}

.clearSearch {
  position: absolute !important;
  right: 45px;
}

/* Table */
.divTable {
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.tableWrap {
  width: max-content;
}

.trow {
  display: flex;
}

.bgRefund {
  background: #F0F0F0 !important;
}

.thead .trow .tdata {
  height: 65px;
}

.tdata {
  padding: 5px;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tdata.no {
  width: 60px;
  border-left: 1px solid #dee2e6;
  flex-shrink: 0;
}

.tdata.clientName {
  width: 200px;
  flex-shrink: 0;
}

.tdata.rekeningRefund {
  width: 225px;
  flex-shrink: 0;
  min-height: 65px;
}

.textBank{
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 175px;
}

.editButton {
  color: #e10009;
  border: 1px solid #e10009;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
  padding: 8px 10px;
  cursor: pointer;
}

.tdata.status {
  flex-direction: column;
  width: 150px;
  flex-shrink: 0;
}

.tdata.projectId,
.tdata.materialOrder,
.tdata.percentage {
  width: 110px;
  flex-shrink: 0;
}

.tdata.amount,
.tdata.feeAdmin,
.tdata.feeRefund,
.tdata.totalRefund {
  width: 130px;
  flex-shrink: 0;
}

.dataRekening {
  overflow-x: auto;
  text-align: left;
}

.tdata.action {
  position: sticky;
  right: -0.5px;
  width: 100px;
  flex-shrink: 0;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #dee2e6;
}

.paidText, .unpaidText {
  padding: 3px 10px;
  border-radius: 100px;
  color: white;
}

.paidText {
  background: #10BD41;
}

.unpaidText {
  background: #E10009;
}

.btnDetail {
  background: #5A9DFD;
}

.emptyData {
  border: 1px solid #dee2e6;
  width: inherit;
  padding: 20px 0px 40px 450px;
}

.textMaterial, .textVariant, .textSubVariant {
  white-space: normal;
}

.table_header tr th {
  vertical-align: middle;
}

/* Select Row Perpage */
.selectRowPerPage {
  width: 90px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding-left: 10px;
  outline: none;
}

.wrapButtonClose {
  border-top: 1px solid #dee2e6;
  margin-right: -16px;
  margin-left: -16px;
}

@media (min-width: 576px) {
  .modal_refund {
    max-width: 90%;
  }
}

@media (min-width: 992px) {
  .modal_refund {
    max-width: 900px;
  }
}